<template>
  <main>
    <top-view cn="联系我们" en="Contact Us" :img="require('../../assets/img/bg_10@2x.png')"/>
    <div class="map-view">
      <div class="map-container">
        <div id="baidu-map" style="height: 400px; width: 1000px"></div>
      </div>
      <div class="map-view__text-container">
        <div class="map-view__text">
          <h3>地址</h3>
          <p>广东省广州市海珠区保利中悦广场</p>
          <h3>商务合作</h3>
          <p>400-188-3633</p>
        </div>
      </div>

    </div>
  </main>
</template>

<script>
  import TopView from "../../components/common/TopView"
  export default {
    name: "contact",
    components: {
      TopView
    },
    metaInfo: {
      title: '乐享无限官网-联系我们',
      meta: [
        {
          name: 'keyWords',
          content: '乐享无限联系方式,智菁通,乐享无限商务合作',
        },
        {
          name: 'description',
          content: '乐享无限集团及广州智菁通信息科技有限公司位于广东省广州市海珠区保利中悦广场,商务合作请联系400-188-3633'
        }
      ]
    },
    mounted() {
      // 引入百度地图
      let script = document.createElement('script')
      script.src = 'https://api.map.baidu.com/getscript?type=webgl&v=1.0&ak=qi0EjmGBASVzOAtbkD8hrAmmHCAFnIRE&services=&t=20200522104216'
      script.type = 'text/javascript'
      let css = document.createElement('link')
      css.rel = 'stylesheet'
      css.type = 'text/css'
      css.href = 'https://api.map.baidu.com/res/webgl/10/bmap.css'
      document.body.appendChild(script)
      document.body.appendChild(css)
      script.onload = () => {
        let map = new BMapGL.Map("baidu-map");
        // 创建地图实例
        let point = new BMapGL.Point(113.389885, 23.109682);
        let marker = new BMapGL.Marker(new BMapGL.Point(113.389885, 23.109682));
        // 创建点坐标
        map.centerAndZoom(point, 16);
        map.addOverlay(marker);
        // 初始化地图，设置中心点坐标和地图级别
      }

    }
  }
</script>

<style lang="scss" scoped>
  .map-container {
    height: 400px;
    max-width: 1000px;
    width: 60%;
    overflow: hidden;
    position: relative;
  }
  #baidu-map {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  main {
    text-align: center;
  }
  .map-view {
    @include clearfix;
    margin: 100px auto;
    display: flex;
    justify-content: space-between;
  }

  #baidu-map {
    float: left;
  }
  .map-view__text-container {
    position: relative;
    float: left;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .map-view__text {
    text-align: left;
    margin-left: 25px;
    h3 {
      font-size: 28px;
      margin-bottom: 40px;
      &:nth-child(1),
      &:nth-child(3){
        &::before {
          content: '';
          display: inline-block;
          width: 30px;
          height: 30px;
          margin-right: 10px;
          background: url("../../assets/img/icon_add@2x.png") no-repeat center center;
        }
      }
      &:nth-child(3)::before {
        vertical-align: bottom;
        background: url("../../assets/img/icon_call@2x.png") no-repeat center center;
      }
    }
    p {
      font-size: 16px;
      margin-bottom: 60px;
      &:nth-child(4) {
        margin: 0;
      }
    }
  }
</style>
